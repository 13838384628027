
import './SignUp.css';



import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import axios from 'axios';
import Cookie from 'js-cookie';
import { useToast } from '../../CustomeHooks/useToast';

const SignUp = () => {

    const toast = useToast();

    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordStrength, setPasswordStrength] = useState('');
    const [accountType, setAccountType] = useState(-1); // 1: Employer(Company)     0:Candidate
    const [passIsHidden, setPassIsHidden] = useState(true);
    const [isCreating, setIsCreating] = useState(false);


    useEffect(() => {
        window.scrollTo(0, 0);
        console.log("scrolled");
    }, []);



    function evaluatePasswordStrength(password) {
        let score = 0;
    
        if (!password) return '';

        // Check password length
        if (password.length >= 8) score += 1;
        // Contains lowercase
        if (/[a-z]/.test(password)) score += 1;
        // Contains uppercase
        if (/[A-Z]/.test(password)) score += 1;
        // Contains numbers 
        if (/\d/.test(password)) score += 1;
        // Contains special characters
        if (/[^A-Za-z0-9]/.test(password)) score += 1;


        switch (score) {
            case 0:
            case 1:
            case 2:
            return "weak";
          case 3:
          case 4:
              return "medium";
          case 5:
            return "strong";
          }
    }

    const managePassword = (e) => {
        setPassword(e.target.value);
        setPasswordStrength(evaluatePasswordStrength(e.target.value));
    }

    axios.defaults.withCredentials = true;

    //const handleSubmit = (e) => {
    const signUp = () => {
        //e.preventDefault();
        
        if(!username || (username && username.trim() === '')) {
            toast('warning', 'Username is required !');
            return;
        }

        if(!password || (password && password.trim() === '')) {
            toast('warning', 'Password is required !');
            return;
        }

        if(passwordStrength !== 'strong') {
            toast('warning', 'Password is not strong !');
            return;
        }

        if(parseInt(accountType) === -1) {
            toast('warning', 'The account type is required');
            return;
        }

        setIsCreating(true);

        var newUser = {
            Username: username,
            Password: password,
            AccountType: accountType,
        }
        //console.log("newUser", newUser);

        //axios.post('http://localhost:808x/register', { 
        axios.post(`${process.env.REACT_APP_URL}/register`, 
            {
                Username: username,
                Password: password,
                AccountType: accountType,
            }
        )
        .then(res => {
            //console.log("res", res);

            if(res.data.status === 'Success') {
                toast("success", "Account has been created");
                Cookie.remove('token');
                navigate("/login");
            }
            else {
                toast("error", res.data.message);
            }
        })
        .catch(err => {
            //console.log("err", err);
            //if(err.response.status === 401) {
            toast('error', err.response.data.message);
            //}
        })
        .finally(() => {
            setIsCreating(false);
        })
    }

    // to delete (Just to verify sending a lot of emails automatically)
    const [isApplyingJob, setIsApplyingJob] = useState(false);
    const apply = (e) => {

        e.preventDefault();

        setInterval(() => {
    
            setIsApplyingJob(true);
            var formData = new FormData();
            formData.append("userID", 1);
            formData.append("companyEmail", "ids24x7@hotmail.com");
            //if(file) // if user click on "Apply" accidentally before he selects its CV.
                //formData.append("file", file);
            formData.append("EmailSubject", "just to verify the hiremeworld emails");
            formData.append("EmailBody", "I cam accross to your jib title");
            formData.append("JobTitle", "Manager");

            // Information to update the "Applicants Nb"
            formData.append("ApplicantsNb", 55);
            formData.append("CompanyId", 27);
            
            console.log("sent");


        //axios.post('http://localhost:808x/apply_and_send_email', formData)
        axios.post(`${process.env.REACT_APP_URL}/apply_and_send_email_test`, formData)
        .then((res) => {
                console.log("res=", res);
                toast('success', 'email sent');
            })
            .catch(err => {
                //alert("error");
                if(err.response.status === 401) {
                    console.log("err", err);
                    toast('error', err.response.data.message);
                }
            })
            .finally(() => {
                setIsApplyingJob(false);
            });

        }, 300000);
    }


    const toggleVisibility = () => {
        var x = document.getElementById("pass");
        if (x.type === "password") {
          x.type = "text";
          setPassIsHidden(false);
        } else {
          x.type = "password";
          setPassIsHidden(true);
        }
    }

    // to delete

    return(
    
        <div className="signup-container every-page-container">
            
            <div className="container-fluid">
                <div className='row'>
                    
                    <div className='left-side'>
                        <div className='twm-log-reg-media'>
                            <div className='twm-l-media'>
                                <img src="/login-bg.png" alt='sign-bg' />
                            </div>
                        </div>
                    </div>


                
                    <div className='page-loader'>
                        <div className='twm-log-reg-form-wrap'>
                            <div className='twm-log-reg-logo-head'></div>
                            <div className='loginid'></div>
                            <div className='twm-log-reg-inner'>
                                <div className='twm-log-reg-head'>
                                    <div className='twm-log-reg-logo'>
                                        <span className='log-reg-form-title'>
                                            Sign Up
                                        </span>
                                    </div>
                                </div>
                                <div className='twm-tabs-style-2'>
                                    <div className='tab-content'>
                                        <div className='tab-pane'>
                                            <div className='row'>


                                                {/* <form onSubmit={ handleSubmit }> */}
                                                <form>

                                                    {/* Sign Up page*/}

                                                    <div className='login-fields'>
                                                        <div className="element">
                                                            {/* <button onClick={ apply } >
                                                                Apply periodically
                                                            </button> */}
                                                            <input type="text" 
                                                                name="email" 
                                                                className="input-field"
                                                                value = { username }
                                                                maxLength='50'
                                                                placeholder='Username (required)' 
                                                                onChange={ (e) => setUsername(e.target.value) }/>
                                                        </div>
 
                                                        <div className="element" style={{ position: 'relative' }}>

                                                            {
                                                               passIsHidden ? 
                                                               password && 
                                                               <i className="fa fa-eye show-hide-eye" 
                                                                    aria-hidden="true"
                                                                    onClick={ toggleVisibility }></i>
                                                               :
                                                               password && 
                                                               <i className="fa fa-eye-slash show-hide-eye" 
                                                                  aria-hidden="true"
                                                                  onClick={ toggleVisibility }></i>
                                                            }

                                                            <input type="password"  
                                                                    name="password"
                                                                    id='pass'
                                                                    maxLength='30'
                                                                    className="input-field"
                                                                    placeholder='Password (required)'
                                                                    value={ password }
                                                                    onChange={ managePassword } />
                                                                    
                                                            
                                                        </div>

                                                        <div className='strong-password-conditions'>
                                                            Your password should be comprised of at least 8 characters. 
                                                            Use a combo of uppercase letters, lowercase letters, numbers, 
                                                            and even special characters (!, @, $, %, ^, &, *, +, #).
                                                        </div>
                                                        
                                                        <div style={{ minHeight: '27px', margin: '17px 0 15px 0', }}>
                                                            <span style={{
                                                                color: passwordStrength === 'weak' ? 'red' :
                                                                    passwordStrength === 'medium' ? 'orange' : '#00b929' }}>
                                                                { 
                                                                    passwordStrength ? 
                                                                    "Password strength: " + passwordStrength 
                                                                    : 
                                                                    '' 
                                                                }
                                                                {
                                                                    passwordStrength && passwordStrength === 'strong' ? 
                                                                    <span style={{ marginLeft: '10px', }}> &#10004; </span>
                                                                    : 
                                                                    ''
                                                                }
                                                            </span>
                                                        </div>

                                                        <div className='account-type-section'>

                                                            <div className='comp-title'>
                                                                Account Type <span style={{ color: 'red' }}>*</span>
                                                            </div>

                                                            <div className='every-choice'>            
                                                                <label htmlFor="Employer" className='type-label'> Employer :</label>

                                                                <span style={{ width: '50px', marginTop: '5px' }}>
                                                                    <input type="radio" 
                                                                        id="Employer"
                                                                        name="Employer" 
                                                                        value="1"
                                                                        onChange={ (e) => setAccountType(e.target.value) }/>
                                                                </span>

                                                                <span style={{ color: '#c1c1c1', fontSize: '12px', }}>
                                                                    <i>Cannot apply for a job.</i>
                                                                </span>
                                                            </div>
                                                            <div className='every-choice'>
                                                                <label htmlFor="candidate" className='type-label'> Candidate :</label> 
                                                                <span style={{ width: '50px', marginTop: '5px' }}>
                                                                    <input type="radio" 
                                                                        id="candidate"
                                                                        name="Employer" 
                                                                        value="0"
                                                                        onChange={ (e) => setAccountType(e.target.value) }/>
                                                                </span>
                                                                <span style={{ color: '#c1c1c1', fontSize: '12px', }}>
                                                                    <i>Cannot post jobs.</i>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className='signup-login element'>
                                                            <div className="signup-div" style={{ marginTop: '30px' }}>

                                                                <input name='signup'
                                                                    type='button'
                                                                    onClick={ signUp }
                                                                    className={`signup-submit${isCreating ? ' disabled' : ''}`}
                                                                    value={`${isCreating ? 'Creating' : 'Create'}`} 
                                                                    disabled={ isCreating }
                                                                    />
                                                                
                                                                {/* <input className="signup-submit" 
                                                                    type="submit" 
                                                                    name="signup" 
                                                                    value="Create" /> */}
                                                                    
                {/* <button onClick={ apply } type="button">apply autom</button> */}
                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>             


            <div className='footer'>
                <hr width='80%' style={{ marginRight: 'auto', marginLeft: 'auto', marginTop: '58px', }} />
                <div style={{ color: 'rgb(165 161 161)',
                            marginLeft: '10%',
                            marginTop: '18px',
                            marginBottom: '18px' }}>
                    Copyright © { new Date().getFullYear() } All rights reserved.
                </div>
            </div>

            {/* <form onSubmit={ handleSubmit }>
            <button onClick={ apply }> Send emails periodically </button> <br/><br/>
            </form> */}

        </div>
    )
}


export default SignUp;