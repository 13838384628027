
import './StudentDetails.css';

import { Link, useParams, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useGetByID from '../../CustomeHooks/useGetByID';
import { getEmploymentTypes, getWorkPlaces, getGenders, showLocalTime  } from '../../Javascript_Functions_to_export/Utils';

import Footer from '../Footer/Footer';

import NotFound from '../../MyComponents/NoFound/NotFound';

import detailsPic from '../../aImages/jobDetails.jpg';

import axios from 'axios';

import FileUploader from '../Uploader/FileUploader';

import parse from 'html-react-parser';

import { useNavigate } from "react-router-dom";

import useGetJobWithFullDetails from '../../CustomeHooks/useGetJobWithFullDetails';

import { useToast } from '../../CustomeHooks/useToast';

import { getUserFromCookie } from '../../Javascript_Functions_to_export/Utils';

import Cookie from 'js-cookie';

const StudentDetails = () => {

    //const [userAllowed, setUserAllowed] = useState(true);
    
    const navigate = useNavigate();

    const toast = useToast();
    const { id } = useParams();

    const { lsData: currItem, isLoading, found } = 
    //useGetJobWithFullDetails('http://localhost:808x/job_full_details/' + id);
    useGetJobWithFullDetails(`${process.env.REACT_APP_URL}/job_full_details/${id}`);

    const [currentJob, setCurrentStudent] = useState(null);

    useEffect(() => {
        //console.log("item = ", currItem);
        // change the "PostedDate" of currItem, then set the final object.
        if(currItem) {


            // var arra = currItem.ApplicantsIDs.split(',');
            // console.log("arra=", arra);

            // var Id = getUserFromCookie(Cookie.get('token')).ID + '';
            // console.log("getUserFromCookie(Cookie.get('token')).ID=", Id.toString());

            // console.log("contains =", currItem.ApplicantsIDs.split(',').includes(getUserFromCookie(Cookie.get('token')).ID.toString()));


            //console.log("new Date(currItem.PostingDate) = ", new Date(currItem.PostingDate));

            // var datetimeMilli = new Date(currItem.PostingDate).getTime();
            // var d = new Date();
            // datetimeMilli += -d.getTimezoneOffset() * 60000; // 1 * timeZoneOffset 
            // var dateLocal = new Date(datetimeMilli).toLocaleString('en-US', { hour12: false });
            // dateLocal = dateLocal.slice(0, dateLocal.indexOf(","));
          
            // var monthNb = dateLocal.slice(0, dateLocal.indexOf("/"));
            // var monthSt;
            // monthNb == 1 ? monthSt = "Jan" :
            // monthNb == 2 ? monthSt = "Feb" :
            // monthNb == 3 ? monthSt = "Mar" :
            // monthNb == 4 ? monthSt = "Apr" :
            // monthNb == 5 ? monthSt = "May" :
            // monthNb == 6 ? monthSt = "Jun" :
            // monthNb == 7 ? monthSt = "Jul" :
            // monthNb == 8 ? monthSt = "Aug" :
            // monthNb == 9 ? monthSt = "Sep" : 
            // monthNb == 10 ? monthSt = "Oct" :
            // monthNb == 11 ? monthSt = "Nov" :
            // monthSt = "Dec";

            // currItem.PostingDate = monthSt + dateLocal.slice(dateLocal.indexOf("/"));
            // console.log(dateLocal);
            setCurrentStudent(showLocalTime(currItem));
        }
    }, [currItem]);
    
    //console.log("id ", id);
    const [file, setFile] = useState();

    const companyProfileAsHTML = currentJob ? 
                                 currentJob.CompanyProfile : 
                                 null;

    // const xx = (_files) => {
    //     setFile(_files[0]);
    // }

    const [companyLogo, setEmailAsBase64] = useState('');
    const xx = (_files) => {
        setFile(_files[0]);

        var reader = new FileReader();
        reader.onloadend = function() {
             setEmailAsBase64(reader.result);
        }
        reader.readAsDataURL(_files[0]);
    }

    //const [categories, setCategories] = useState();
    const [employmentTypes, setEmploymentTypes] = useState();
    const [workPlaces, setWorkPlaces] = useState();
    const [genders, setGenders] = useState();


    useEffect(() => {
        // initialize all Dummy values (Just like 'Othman Fayed in <<WPF>>')
        //setCategories(getJobCategories());
        setEmploymentTypes(getEmploymentTypes());
        setWorkPlaces(getWorkPlaces());
        setGenders(getGenders());
        //console.log('cat = ', getJobCategories());
    }, []);

    axios.defaults.withCredentials = true;

    const apply = () => {
        
        if(!Cookie.get('token')) {
            toast('warning', 'Please Login to Apply !');
            return;
        }

        if(getUserFromCookie(Cookie.get('token')).AccountType === 1) {   // Employer=1, Candidate=0
            toast('warning', 'Employers cannot apply for a job !');
            return;
        }

        var userID = getUserFromCookie(Cookie.get('token')).ID;
        

        if(currentJob.ApplicantsIDs) {
            var applicantsIDs = currentJob.ApplicantsIDs.split(',');
            
            if(applicantsIDs.includes(userID.toString())) {
                toast("warning", "Already applied for this job!");
                return;
            }
        }
        

        //debugger;
        //var fileURL = file.URL;      // "https://cdn.sstatic.net/clc/img/jobs/bg-remote-header-sm.png";
        //var fileName = '';

        //if(file.type === 'application/pdf') {   //     Additional File (2MB max): (.zip,.doc,.docx,.pdf,.txt)
        //    fileName = "CV.pdf";
        //} else {
        //    fileName = "CV.docx";
        //}

        // use formData = new FormData() instead of these 3 lines
        // var applicationToSend = {
        //     companyEmail : currentJob.Email,
        //     filePath_AsAtt: 'C:/Users/user/Downloads/' + fileName
        // }
    
        //return;

        /*//fetch('http://localhost:808x/sendemail', {
        fetch('http://localhost:808x/post_a_job', { 
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)  // it was newAirplane in "AviMall.com"
        })
        .then(() => {
            //if(res.data.Status === 'Success')
                //toast("Your application has been sent successfuly");
            //else
                //toast("There was an error while sending your application.");
        })
        .catch(e => {

        })*/

        setIsApplyingJob(true);

        var formData = {}; //new FormData();

        //formData.append("jobID", id);
        
        formData.userID = userID;
        formData.companyEmail = currentJob.Email;
        
        if(companyLogo) { // if user click on "Apply" accidentally before he selects its CV.
            //formData.append("file", file);   //  old code
            formData.companyLogo = companyLogo;
        }
        formData.EmailSubject = currentJob.EmailSubject;
        formData.EmailBody = currentJob.EmailBody;
        formData.JobTitle = currentJob.JobTitle;

        // Information to update the "Applicants Nb"
        formData.ApplicantsNb = currentJob.ApplicantsNb;
        formData.CompanyId = currentJob.JobID;
        formData.ApplicantsIDs = currentJob.ApplicantsIDs;
        
        // step 1/ send email to desired company
        //axios.post('http://localhost:808x/apply_and_send_email', formData)

        axios.post(`${process.env.REACT_APP_URL}/apply_and_send_email`, formData)   // old code
        //axios.post('https://hiremeworld.com/Upload_File', formData)                     // new code

       .then((res) => {
            if(res.data.status === 'success')
                navigate('/sent_successfully', { state: { LastVisitedPage: "job/details/" + id } });
            else
                toast('error', res.data.message);
        })
        .catch(err => {
            //if(err.response.status === 401) {
                toast("error", err.response.data.message);
                //navigate("/login", { state: { LastVisitedPage: "job/details/" + id } });
            //}
        })
        .finally(() => {
            setIsApplyingJob(false);
        });
    }
    
    const [isAddingCV, setIsAddingCV] = useState(false);
    const [isApplyingJob, setIsApplyingJob] = useState(false);

    const getIsAddingCV = (_isAdding) => {
        //console.log("adding = ", _isAdding);
        setIsAddingCV(_isAdding);
    }
    
    //if(!currentJob && !isLoading) 
     if(found === -1) 
         return (
  
             <NotFound />
         )

    if(isLoading) {
        return(
            <div style={{ marginRight: 'auto', 
                          marginLeft: 'auto', 
                          position: 'relative', 
                          top: '50%', 
                          fontSize: '40px',
                          color: '#c1c1c1' }}>
                    Loading ...
            </div>
        )
    }
    
    if(currentJob)
    return(

        
        <div className='every-page-container'>
{/*             
            {
                currentJob ? 
                <span style={{ color: 'white' }}>fi object</span> :
                <span style={{ color: 'white' }}>ma fi object</span>
            } */}

            <div className='apply-job-container' >
                { 
                    isLoading ? 
                    'Loading please wait ...' :  
                    
                    !currentJob ? 
                    <div>
                        <NotFound />
                    </div> :

                    <>
                        <div className='big-picture'>
                            <img src={ detailsPic } 
                                alt="details" 
                                className='big-pic' />
                        </div>

                        <div className='line1 contain-company-logo'>
                                <div className='col1'>

                                    {
                                        currentJob.Logo !== null && !currentJob.IsConfidential ? 
                                            // <img src={ "/" + currentJob.Logo } 
                                            <img src={ currentJob.Logo } 
                                                alt="Company Logo" 
                                                className='company-pic' /> 
                                            :
                                            currentJob.Logo === null && !currentJob.IsConfidential ?
                                            <i className="fa fa-picture-o" 
                                                aria-hidden="true"
                                                style={{ fontSize: '125px', opacity: '0.2'}}>
                                            </i> 
                                        :
                                        <i className="fa fa-shield company-pic" 
                                            aria-hidden="true" 
                                            style={{ fontSize: '122px', marginTop: '-4px' }}></i>
                                    }

                                    {/* {
                                        currentJob.IsConfidential === 1 ?
                                        <i className="fa fa-shield company-pic" 
                                        aria-hidden="true" 
                                        style={{ fontSize: '122px', marginTop: '-4px'}}></i> 
                                        :
                                        <img src={"/" + currentJob.Logo } className='company-pic' />
                                    } */}
                                </div>
                                
                                <div className='col2'>
                                    {
                                        Cookie.get('token') &&
                                        currentJob.ApplicantsIDs &&
                                        currentJob.ApplicantsIDs.split(',').includes(getUserFromCookie(Cookie.get('token')).ID.toString()) ?
                                        <button className='applied-btn'> 
                                            Applied
                                        </button>
                                        :

                                        <button name='applyjob_2'
                                                type='button'
                                                onClick={ apply }
                                                className={`apply-btn ${ isApplyingJob === true || isAddingCV === true ? 'disabled' : ''}`}
                                                // value={`${ isApplyingJob || isAddingCV ? 'Posting' : 'Post'}`} 
                                                disabled={ isApplyingJob === true || isAddingCV === true }>
                                                { 
                                                    isAddingCV === true ? 'Apply' :
                                                    isApplyingJob === true ? 'Applying' : 
                                                    'Apply'
                                                } 
                                        </button>

                                        // isApplyingJob || isAddingCV ? 
                                        // <button className='apply-btn disabled'> 
                                        //     {/* { isApplyingJob || isAddingCV ? 'Applying' : 'Apply' } */}
                                        //     Apply
                                        // </button>
                                        // : 
                                        // <button onClick={ apply } className='apply-btn'>
                                        //     Apply
                                        // </button>
                                    }
                                </div>
                        </div>

                        

                        <div className='job-summary'>
                            
                            {/* <div className='line1'> ... </div>   has been moved outside*/}

                            <div className='title-line1'>
                                { currentJob && currentJob.JobTitle }
                            </div>

                            <div className='location-line2'>
                                Company : { 
                                    currentJob && currentJob.IsConfidential === 1 ? 
                                    'Confidential' : 
                                    currentJob && currentJob.CompanyName
                                } 
                            </div>

                        </div>

                        <div className='job-details'>
                            <div className='col1'>
                                <div className='job-information-text'>Job Information</div>
                                    <JobInformationDetail icon=<i className="fa fa-calendar" aria-hidden="true"></i>
                                                        text='Posted Date' 
                                                        value={ currentJob && currentJob.PostingDate } />

                                    <JobInformationDetail icon=<i className="fa fa-map-marker" aria-hidden="true"></i> 
                                                        text='Location' 
                                                        value=
                                                        { 
                                                            // currentJob && currentJob.Location 
                                                            currentJob.Location === 'null' ||
                                                            currentJob.Location === null ||
                                                            currentJob.Location.trim() === '' ?
                                                            'Not specified' :
                                                            currentJob.Location
                                                        } />
                                                        
                                    <JobInformationDetail icon=<i className="fa fa-venus-mars" aria-hidden="true"></i>
                                                    text='Gender' 
                                                    value={ 
                                                            genders && currentJob && 
                                                            genders.find(g => g.Id === currentJob.Gender) ? 
                                                            genders.find(g => g.Id === currentJob.Gender).Name :
                                                            <span className='unknown-val'>Unknown</span> 
                                                                } />
                                    <JobInformationDetail icon=<i className="fa fa-money" aria-hidden="true"></i>
                                                    text='Salary' 
                                                    value={ currentJob && 
                                                        ( currentJob.Salary === 'null' || 
                                                            currentJob.Salary === null || 
                                                            currentJob.Salary.trim() === '' )? 
                                                        'Not Specified' : 
                                                        currentJob && currentJob.Salary } />

                                    <JobInformationDetail icon=<i className="fa fa-pencil-square-o" aria-hidden="true"></i> 
                                                    text='Type' 
                                                    value={ 
                                                        employmentTypes && currentJob && 
                                                        employmentTypes.find(g => g.Id === currentJob.EmploymentType) ? 
                                                        employmentTypes.find(g => g.Id === currentJob.EmploymentType).Name :
                                                        <span className='unknown-val'>Unknown</span> 
                                                    } />

                                    <JobInformationDetail icon=<i className="fa fa-sitemap" aria-hidden="true"></i>
                                                    text='Workplace' 
                                                    value={ 
                                                        workPlaces && currentJob && 
                                                        workPlaces.find(g => g.Id === currentJob.Workplace) ?
                                                        workPlaces.find(g => g.Id === currentJob.Workplace).Name : 
                                                        <span className='unknown-val'>Unknown</span> 
                                                    } />
                            </div>


                            <div className='col2'>

                                <div className="job-description-section">
                                    <div className='section-title'>Job Description</div>
                                    {/* <div className='details' 
                                         dangerouslySetInnerHTML={{ __html: currentJob && currentJob.JobDescription.replace("background-color", "backgroundColor") }} /> */}
                                    <div>
                                    { 
                                        currentJob && 
                                        currentJob.JobDescription && 
                                        parse(currentJob.JobDescription) 
                                    }
                                    </div>
                                </div>

                                <div className="company-profile">
                                    <div className='section-title'>Company Profile</div>
                                    {/* <div className='details' dangerouslySetInnerHTML={{ __html: companyProfileAsHTML }} /> */}
                                    <div>
                                    { 
                                        currentJob &&
                                        currentJob.Profile && 
                                        parse(currentJob.Profile) 
                                        // typeof (currentJob.Profile)
                                    }
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* <FileUploader getFiles={ getFiles } multiple="multiple"/> */}
                            
                        {/* <div className='apply-btn-bottom'>
                       
                            <button onClick={ apply } className='apply-btn'> 
                                { isApplyingJob ? 'Applying' : 'Apply' }
                            </button>
                         
                        </div> */}

                        <div className='upload-line'>
                            <FileUploader XXX={xx} 
                                        isAddingFn={ getIsAddingCV } 
                                        _accept=".zip, .pdf, .docx" />

                            <div className='apply-col2'>
                                {
                                    Cookie.get('token') &&
                                    currentJob.ApplicantsIDs &&
                                    currentJob.ApplicantsIDs.split(',').includes(getUserFromCookie(Cookie.get('token')).ID.toString()) ?
                                    <button className='applied-btn'> 
                                        Applied
                                    </button>
                                    :


                                    <button name='applyjob_2'
                                                type='button' 
                                                onClick={ apply }
                                                className={`apply-btn ${ isApplyingJob === true || isAddingCV === true ? 'disabled' : ''}`}
                                                // value={`${ isApplyingJob || isAddingCV ? 'Posting' : 'Post'}`} 
                                                disabled={ isApplyingJob === true || isAddingCV === true }>
                                                { 
                                                    isAddingCV === true ? 'Apply' :
                                                    isApplyingJob === true ? 'Applying' : 
                                                    'Apply'
                                                } 
                                    </button>

                                    // isApplyingJob || isAddingCV ? 
                                    // <button className='apply-btn disabled'> 
                                    //     {/* { isApplyingJob || isAddingCV ? 'Applying' : 'Apply' } */}
                                    //     Apply
                                    // </button>
                                    // : 
                                    // <button onClick={ apply } className='apply-btn'> 
                                    //     {/* { isApplyingJob || isAddingCV ? 'Applying' : 'Apply' } */}
                                    //     Apply
                                    // </button>
                                }
                            </div>
                        </div>
                    
                    </>
                }
            </div>

            <br/><br/>
            <hr className='hor-line' style={{ marginRight: 'auto', marginLeft: 'auto',  }} width='80%' />
            <div style={{ color: 'rgb(165 161 161)',
                        marginLeft: '10%',
                        marginTop: '18px',
                        marginBottom: '18px' }}>
                Copyright © { new Date().getFullYear() } All rights reserved.
            </div>
        </div>
    );
}


export default StudentDetails;


const JobInformationDetail = ({ icon, text, value}) => {

    return(
        <div className='single-info-container'>

            <div className='icon'>
                { icon }
            </div>

            <div className='textes'>
                <div className='title'>{ text }</div>
                <div className='value'>{ value }</div>
            </div>

        </div>
    )

}