

import './HomePage.css';
import WebSiteIcon from '../MyComponents/WebSiteIcon/WebSiteIcon';
import handsomePerson from '../aImages/happy_person_5_trans_1.png';
import hiringWhite from '../aImages/hiring_white.jpg';
import { Link } from 'react-router-dom';
import CountUp from '../MyComponents/CountUp/CountUp';
import Footer from '../MyComponents/Footer/Footer';
import { getJobCategories } from '../Javascript_Functions_to_export/Utils';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

import FileUploader from '../MyComponents/Uploader/FileUploader';


import { useLocation } from 'react-router-dom';

//import tappayments from '@api/tappayments';


const HomePage = () => {

    const [applicantsNumber, setApplicantsNumber] = useState(0);
    const [candidatesnb, setCandidatesnb] = useState(0);
    const [companiesNumber, setCompaniesNumber] = useState(0);
    const [jobsNumber, setJobsNumber] = useState(0);

    const loc = useLocation();

    
    
    const [file, setFile] = useState();
    const getIsAddingCV = (_isAdding) => {
        //console.log("adding = ", _isAdding);
        //setIsAddingCV(_isAdding);
    }

    const [companyLogo, setEmailAsBase64] = useState('');
    const xx = (_files) => {
        setFile(_files[0]);

        var reader = new FileReader();
        reader.onloadend = function() {
             //console.log("file", _files[0]);
             //console.log("reader.result.split('base64,')[1]", reader.result.split("base64,")[1]);
             //setCompanyLogo64(reader.result.split("base64,")[1]);  // to display an image we need the "base64" part

             setEmailAsBase64(reader.result.split("base64,")[1]);
        }
        reader.readAsDataURL(_files[0]);
    }


    const testPort = () => {
        
        //var formData = new FormData();
        //formData.append("file", file);
        //console.log("file=", file);

        //var formData = { _fileName: file.name, _URL: file.URL }
        var formData = { logo : companyLogo }

        //console.log("formData=", formData, " ||| file", file);

        axios.post(`${process.env.REACT_APP_URL}/logo`, formData)
       .then((res) => {
            console.log("res=", res);
        })
        .catch(err => {
            console.log("err=", err);
        })
        .finally(() => {
        });

        return;
        //axios.post('https://hiremeworld.com/apply_and_send_email_test', formData)
        axios.post(`${process.env.REACT_APP_URL}/apply_and_send_email_test`, formData)
       .then((res) => {
            console.log("res=", res);
        })
        .catch(err => {
            console.log("err=", err);
        })
        .finally(() => {
        });
    }


    axios.defaults.withCredentials = true;
    useEffect(() => {

        // Everything about XMLHttpRequest() here: https://javascript.info/xmlhttprequest

        // var xhr = new XMLHttpRequest();
        // //xhr.setDisableHeaderCheck(true); where on the earch ??
        // // open the request with the verb and the url
        // xhr.open('GET', `${process.env.REACT_APP_URL}/applicantsnumber`)
        // xhr.setRequestHeader("Content-Type", "application/json");
        // xhr.addEventListener('load', () => {
        //     // update the state of the component with the result here
        //     console.log("using XMLHttpRequest", JSON.parse(xhr.response)[0].TotalApplicants);
        //     setApplicantsNumber(JSON.parse(xhr.response)[0].TotalApplicants);
        // });
        // // send the request
        // xhr.send();
        // xhr.addEventListener('end', () => {
        //     //setApplicantsNumber(xhr.responseText[0].TotalApplicants);
        //     //setApplicantsNumber(JSON.parse(xhr.response)[0].TotalApplicants);
        // });

        //return;

        //var headers = new Headers();
        //headers.append('Content-Type', 'application/json');
        //headers.append('Accept', 'application/json');
        //headers.append('Access-Controll-Allow-Origin', 'http://localhost:3000');

        // try {
        //     var response = fetch(`${process.env.REACT_APP_URL}/applicantsnumber`, {
        //         method: 'GET',
        //         mode: 'no-cors',         // Pin this value           //'same-origin',
        //         //redirect: 'follow',
        //         credentials: 'include', // Don't forget to specify this if you need cookies
        //         //headers: headers,
        //      })

        //      if(response.ok)
        //         console.log("response.json()", response.json());
        //      else
        //      throw new Error("Data could not be fetched");

        // } catch (error) {
        //     console.log("res 1", error);
        // }
        


        //axios.get('http://localhost:808x/applicantsnumber')
        axios.get(`${process.env.REACT_APP_URL}/applicantsnumber`//, { withCredentials:true }
            //  , {
            //      headers: {
            //          //'Content-Type': 'application/json',
            //          //Accept: "application/json",Access-Control-Allow-Origin
            //          //'Access-Control-Allow-Origin': "http://localhost:3000"
            //          'Access-Control-Allow-Origin': process.env.REACT_APP_ORIGIN
            //      }
            // }
                    // headers: {
                    //     "Access-Control-Allow-Origin": "*",
                    //     "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                    //     "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
                    // }

                     //headers: {
                        //         //"Content-Type": "application/json",
                        //     }
            // }
        )
        .then(res => {
            //console.log("applicantsnumber from axios", res);
            setApplicantsNumber(res.data[0].TotalApplicants);
        })

        
        //axios.get('http://localhost:808x/candidatesnb')
        axios.get(`${process.env.REACT_APP_URL}/candidatesnb`
            //  , {
            //      headers: {
            //          //'Content-Type': 'application/json',
            //          //Accept: "application/json",Access-Control-Allow-Origin
            //          //'Access-Control-Allow-Origin': "http://localhost:3000"
            //          'Access-Control-Allow-Origin': process.env.REACT_APP_ORIGIN
            //      } , withCredentials:true 
            // }

        )
        .then(res => {
            setCandidatesnb(res.data[0].CandidatesNumber);
            //console.log("CandidatesNumber", res);
        })

        
        
        //axios.get('http://localhost:808x/companiesnumber')
        axios.get(`${process.env.REACT_APP_URL}/companiesnumber`)
        .then(res => {
            setCompaniesNumber(res.data[0].NumberOfCompanies);
            //console.log("NumberOfCompanies", res);
        })

        
        //axios.get('http://localhost:808x/postedjob_number')
        axios.get(`${process.env.REACT_APP_URL}/postedjob_number`)
        .then(res => {
            setJobsNumber(res.data[0].NumberOfPostedJobs);
            //console.log("NumberOfPostedJobs", res);
        });
        
        // var headers = new Headers();
        // headers.append('Content-Type', 'application/json');
        // headers.append('Accept', 'application/json');

        // fetch('http://localhost:808x/postedjob_number', {
        //     method: 'GET', // POST
        //     mode: 'no-cors', //'same-origin',
        //     redirect: 'follow',
        //     credentials: 'include', // Don't forget to specify this if you need cookies
        //     headers: headers,
        //     //body: JSON.stringify({
        //     //    first_name: 'John',
        //     //    last_name: 'Doe'
        //     //})
        // })

        // return;

        // *******     worked correctly, and fetched all countries.    *******
        // axios.get('https://restcountries.com/v3.1/all')    
        // .then(res => {
        //     console.log("res 3", res);
        // });

        // code worked nb 1
        // axios.get('https://hiremeworld.com/test') 
        // .then(res => {
        //     console.log("res 99", res);
        // })
        // .catch((err) => {
        //     console.log("err=", err);
        // })

        // code worked nb 1
        // axios.get('https://hiremeworld.com/applicantsnumbe') 
        // .then(res => {
        //     console.log("applicantsnumbe from live DB = ", res);
        // })
        // .catch((err) => {
        //     console.log("err=", err);
        // })


        // axios.get('https://hiremeworld.com/applicantsnumbe',   //  did not work     
        //     {
        //         headers: { 
        //             //ContentType: 'application/json',
        //             Accept: 'application/json',
        //             //AccessControlAllowOrigin: '*',
        //             //AccessControlRequestMethod: 'GET',
        //         }
        //     }
        // )    
        // .then(res => {
        //     console.log("res 99", res);
        // });


        // var headers = new Headers();
        // //headers.append('Content-Type', 'application/json');
        // headers.append('content-type', 'application/json');
        // headers.append('Accept', 'application/json');

        // fetch('https://hiremeworld.com/applicantsnumbe', {   //  did not work
        //    method: 'GET',
        //    mode: 'cors', //'same-origin',
        //    //redirect: 'follow',
        //    credentials: 'include', // Don't forget to specify this if you need cookies
        //    headers: headers,
        // })
        // .then((res) => {
        //    console.log("res 1", res);
        // });

        /*
        axios.get('<https://hiremeworld.com/api/applicantsnumbe>') 
        .then(res => {
            console.log("res 3", res);
        });

        axios.get(`<https://hiremeworld.com/api/applicantsnumbe>`) 
        .then(res => {
            console.log("res 8", res);
        });

        axios.get('https://hiremeworld.com/api/applicantsnumbe') 
        .then(res => {
            console.log("res 6", res);
        });

        axios.get(`https://hiremeworld.com/api/applicantsnumbe`) 
        .then(res => {
            console.log("res 7", res);
        });
        
        */

        // axios.get(`https://hiremeworld.com/applicantsnumbe`)         // did not work
        // .then(res => {
        //     console.log("res 3", res);
        // });

        // axios.get('https://dummyjson.com/users')    // did not work
        // .then(res => {
        //     console.log("res 3", res);
        // });

        // const api = axios.create({
        //     baseURL: '<https://hiremeworld.com>'
        // });

        // api.get('/applicantsnumbe')          // did not work
        // .then(res => {
        //     console.log("res 5", res);
        // });

        // axios.get('https://hiremeworld.com/applicantsnumbe')   // did not work
        // .then(res => {
        //     console.log("res 3", res);
        // });

        // axios.get('/applicantsnumbe')   //  did not work
        // .then(res => {
        //     console.log("res 4", res);
        // });

        // const http = axios.create({      //  did not work
        //     baseURL: 'https://hiremeworld.com'
        // });

        // http.get('/applicantsnumbe')
        // .then(res => {
        //     console.log("res 5", res);
        // });

        // axios.get('https://hiremeworld.com',     =>  return index.html code
        //     //{
        //         //headers: {
        //         //'Content-Type': 'application/json',
        //         //'Accept': 'application/json'
        //         //}
        //     //}
        // )   // for test, worked and showed the alert
        // .then(res => {
        //     console.log("res 2", res);
        // })
        // .catch(res => {
        //     console.log("res.data.message", res.data.message);
        // })

        

        // axios.get('https://hiremeworld.com:8081') // did not work
        // .then(res => {
        //     console.log("res 3", res.status);
        // })

        // axios.get('https://hiremeworld.com:8443/applicantsnumbe') // did not work
        // .then(res => {
        //     console.log("res 3", res.status);
        // })

        // axios.get('https://hiremeworld.com:8443/applicantsnumbe/') // did not work
        // .then(res => {
        //     console.log("res 4", res.status);
        // })

        // axios.get('https://hiremeworld.com/')   // did not work
        // .then(res => {
        //     alert("nothing");
        // })

        // axios.get('https://hiremeworld.com/applicantsnumbe/') // did not work
        // .then(res => {
        //     console.log(res);
        // })

        // axios.get('https://hiremeworld.com:3306/applicantsnumbe/') // did not work
        // .then(res => {
        //     console.log(res);
        // })
    },[]);


    return(   <div className="big-home-container every-page-container">

        <div className="home-container">

        { /*<FileUploader XXX={xx} 
                    isAddingFn={ getIsAddingCV } 
                    _accept=".zip, .pdf, .docx"
                    /> <button onClick={testPort}>Test Post</button> */}


            <div className='light-color'>

            </div>

            <div className='dark-color'>

            </div>

            <div className='texts'>
                <div className='text1'>
                    <div className='right-sign'>&#10004;</div>
                    <div className='stay-connect'>
                        Stay connected to get upcoming job with
                        <font color='#1965CF'><i><b> HireMeWorld</b></i></font>
                    </div>
                    {/* <div className='website-word'> HireMe</div> */}
                </div>
                <div className='text2'>
                    Find Your<br/>Perfect <span className='job-word'>Job</span><br/> Platform
                </div>
                <div className='text3'>
                    Explore all the most exciting job roles based on your interest and <br/>
                    study major. your dream job is waiting for you.
                </div>
                <div className='find-job-button'>
                    <Link to='/search'>
                        Find Jobs
                    </Link>
                </div>
            </div>

            <div className='handsome-icons'>
                <div className='big-circle'>

                </div>
                <div className='white-circle'>

                </div>
                <i className="fa fa-file-pdf-o pdf-icon" aria-hidden="true"></i>

                <div className='plus-sign'>
                    <i className="fa fa-plus" aria-hidden="true"></i>
                </div>

                <i className="fa fa-search search-sign" aria-hidden="true"></i>

                <div className='upload-div'>
                    <span><i className="fa fa-paperclip paperclip" aria-hidden="true"></i></span>
                    <span className='upload-cv'>Upload CV</span>
                </div>

                <img src={ handsomePerson }
                     className='handsome-img'
                     alt='handsome' />
            </div>

        </div>


        <div className='jobs-statistics-section'>

            <img src={ hiringWhite } alt="Hiring" className='bottom-img' />

            <div className='bottom-bg'>
                            <div style={{
                                fontWeight: '600',
                            }}>Our Community
                            </div>
                            <div className='join-community'>
                                Join our community of talented<br/>
                                and professionals by applying<br/>for a job today!
                        </div>
            </div>

            <div className='div-data'>
                <div className='counting'>
                    <div className='rotated-div'></div>
                    <div className='logo'><i className="fa fa-file-text-o" aria-hidden="true"></i></div>
                    <div className='number companies'>
                        { applicantsNumber >= 2 ? applicantsNumber - 1 + "+" : applicantsNumber }
                    </div>
                    <div className='title'>User{ applicantsNumber >= 2 ? 's' : '' } Application{ applicantsNumber >= 2 ? 's' : '' }</div>
                </div>
                <div className="counting">
                    <div className='rotated-div'></div>
                    <div className='logo'><i className="fa fa-building-o" aria-hidden="true"></i></div>
                    <div className='user-applications number'>
                        { companiesNumber >= 2 ? companiesNumber - 1 + "+" : companiesNumber }
                    </div>
                    <div className='title'>Compan{ companiesNumber >= 2 ? 'ies' : 'y' }</div>
                </div>
                <div className='counting'>
                    <div className='rotated-div'></div>
                    <div className='logo'><i className="fa fa-users" aria-hidden="true"></i></div>
                    <div className='posted-jobs number'>{ candidatesnb >= 2 ? candidatesnb - 1 + "+" : candidatesnb }</div>
                    <div className='title'>User{ candidatesnb >= 2 ? 's' : '' }</div>
                </div>
                <div className='counting'>
                    <div className='rotated-div'></div>
                    <div className='logo'><i className="fa fa-th-large" aria-hidden="true"></i></div>
                    <div className='categories number'>
                        { jobsNumber >= 2 ? jobsNumber - 1 + "+" : jobsNumber }
                    </div>
                    <div className='title'>Job{ jobsNumber >= 2 ? 's' : '' }</div>
                </div>
            </div>
        </div>

        <div className='jobs-statistics-section2'>

        </div>
        <Footer />

        </div>
    );

}

export default HomePage;

