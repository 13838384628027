
import { useEffect, useState } from "react";

const useGetByID = (url) => {

    const [isLoading, setIsLoading] = useState(true);
    const [lsData, setLsData] = useState({});
    const [found, setFound] = useState(0);

    const [useEmailBody, setEmailBody] = useState('');
    const [useCompanyProfile, setCompanyProfile] = useState('');


    const getData = async () => {
        //console.log('inside get data');

        const response = await fetch(url);
        if(response.ok)
            return response.json();
        else 
            throw new Error("Data could not be fetched");
    }
    useEffect(() => {
        
            getData()
            .then((res) => {
                if(!res || res.length == 0)
                    setFound(-1);
                console.log("res = ", res);
                setLsData(res);

                // this section is just if we are loading a company details.
                if(url.indexOf('company') >= 0) {
                    //console.log('inside useEffect');

                    setEmailBody(res[0].EmailBody);
                    setCompanyProfile(res[0].Profile);
                }

            })
            .catch((error) => {console.log("error = ", error);})
            .finally(() => {
                setIsLoading(false);
            });
    }, [url]);

    //console.log("lsData = ", lsData[0]);  // correct syntax

    if(url.indexOf('company') >= 0) {
        //console.log('in company section', useEmailBody + useCompanyProfile);
        return { lsData: lsData[0], isLoading, found, useEmailBody, useCompanyProfile };
    }
    else {
        //console.log('in Job section');
        return { lsData: lsData[0], isLoading, found };
    }
}

export default useGetByID;









